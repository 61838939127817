import {
  TableCategory,
  TableHeaders,
  CellContentType
} from '@/shared/components/table/models';
import { ToastHelper } from '@/utils/toast.util';

import { BaseClass } from '../base/base.service';

export class RolesProvider extends BaseClass {
  constructor() {
    super('admin/auth/');
  }

  async getRolesData() {
    const table = await this.getRolesTable();
    const {
      UserRoleCategoriesHeaders,
      UserRoleHeaders,
      PermissionsToAreaObject
    } = await this.getRolesTableHeaderData();
    return {
      table,
      UserRoleCategoriesHeaders,
      UserRoleHeaders,
      PermissionsToAreaObject
    };
  }

  async getRolesTable() {
    const data = await this.getRolesTableData();
    await this.getRolesTableHeaderData();
    return data.map(({ role, auths, id }) => {
      const settings = { role, id };
      auths.forEach(
        ({ area, object, action }) =>
          (settings[object.length ? `${area} / ${object}` : area] = action)
      );
      return settings;
    });
  }

  async getRolesTableData() {
    const { data } = await this.get('');
    return data;
  }

  async getRolesTableHeaderData() {
    const { data } = await this.get('configs');

    return this.buildRoleTableHeaders(data);
  }

  async save(row, PermissionsToAreaObject) {
    const data = this.convertRowToAPIFormat(row, PermissionsToAreaObject);
    try {
      await this.post('', data);
    } catch (e) {
      ToastHelper.show(
        'Role save error',
        e?.response?.data?.error,
        5000,
        'danger'
      );
    }
  }

  async update(row, PermissionsToAreaObject) {
    const data = this.convertRowToAPIFormat(row, PermissionsToAreaObject);

    try {
      await this.put(data.id, data);
    } catch (e) {
      ToastHelper.show('Role updated error', e?.response?.data?.error, 5000, 'danger');
    }
  }

  private convertRowToAPIFormat(row, PermissionsToAreaObject) {
    const data: { auths: any[]; role: string; id?: string } = {
      auths: [],
      role: null
    };
    const keysToRemove = ['_rowId', 'action', 'number'];

    Object.entries(row).forEach(i => {
      const [key, action] = i;

      if (keysToRemove.includes(key)) {
        return;
      }

      if (key === 'role') {
        data.role = action as string;
        return;
      }

      if (key === 'id') {
        data.id = action as string;
        return;
      }

      data.auths.push({ ...PermissionsToAreaObject[key], action });
    });

    return data;
  }

  private buildRoleTableHeaders(row) {
    const UserRoleCategoriesHeaders: TableCategory[] = [
      {
        key: 'role',
        label: ' ',
        maxColspan: 1,
        minColspan: 1
      }
    ];
    const UserRoleHeaders: TableHeaders[] = [
      {
        key: 'role',
        label: ``,
        content: { type: CellContentType.Text }
      }
    ];
    const PermissionsToAreaObject = {};

    row.forEach(i => {
      const headerKey = i.object.length ? `${i.area} / ${i.object}` : i.area;

      const roleHeaderIndex = UserRoleHeaders.findIndex(
        header => header.key === headerKey
      );

      if (roleHeaderIndex === -1) {
        PermissionsToAreaObject[headerKey] = {
          object: i.object,
          area: i.area,
          action: i.action
        };
        UserRoleHeaders.push({
          key: headerKey,
          label: i.object,
          collapsed: false,
          rendererKey: i.area,
          content: {
            skipValidation: true,
            type: CellContentType.List,
            list: [{ value: i.action, text: i.action }]
          }
        });
      } else {
        UserRoleHeaders[roleHeaderIndex].content.list.push({
          value: i.action,
          text: i.action
        });
      }
    });
    UserRoleHeaders.push({
      key: 'update',
      label: ``,
      content: {
        type: CellContentType.Button,
        label: 'SAVE'
      }
    });

    row.forEach(i => {
      const categoryIndex = UserRoleCategoriesHeaders.findIndex(
        category => category.key === i.area
      );

      // Add new header if not exist yet
      if (categoryIndex === -1) {
        const categoryCount = UserRoleHeaders.filter(
          header => header.rendererKey === i.area
        );
        UserRoleCategoriesHeaders.push({
          key: i.area,
          label: i.area,
          maxColspan: categoryCount.length,
          minColspan: categoryCount.length
        });
      }
    });

    // Update button
    UserRoleCategoriesHeaders.push({
      key: 'update',
      label: ' ',
      maxColspan: 1,
      minColspan: 1
    });

    return {
      UserRoleCategoriesHeaders,
      UserRoleHeaders,
      PermissionsToAreaObject
    };
  }
}

export const RolesService = new RolesProvider();
